import React from "react"
import { Link } from "gatsby"

import "./layout.scss"

const Layout = ({ location, title, children }) => {
  let links = [
    {
      "path": "/",
      "name": "Home",
    },
  ]

  return (
    <div className="page-root">
      <header className="page-header">
        <h1 className="main-heading">
          <Link to="/">{title}</Link>
        </h1>
      </header>
      <nav className="page-navigation">
        <ul>
          {links.map(({ path, name }) => (
            <li key={path}>
              <Link to={path} activeClassName={`${__PATH_PREFIX__}${path}` === location.pathname ? "active" : ""}>{name}</Link>
            </li>
          ))}
        </ul>
      </nav>
      <main className="page-content">{children}</main>
      <footer className="page-footer">
        © 2020-{new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
